import { useMemo } from 'react'
import { aOrganization, useAtomValue } from '~/atoms'
import { Listing } from '~/models'
import useIsEmbed from './useIsEmbed'

export default function useMapListingLink(listing: Listing) {
  const isEmbed = useIsEmbed()
  const organization = useAtomValue(aOrganization)

  return useMemo(() => {
    const link = `/listings/${listing.slug}`
    if (isEmbed && organization!.options.wordpressSiteUrl) {
      const base = organization!.options.wordpressSiteUrl!.replace(/\/$/, '')
      return `${base}${link}`
    }
    return link
  }, [isEmbed, listing])
}
